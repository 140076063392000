import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

function Experience() {
  return (
    <section id='experience'>
    <h5>What Skills I Have</h5>
    <h2>My Experience</h2>

    <div className="container experience__container">
      <div className="experience__frontend">
        <h3>Frontend</h3>
        <div className="experience__content">
          <article className='experience__details'>
          <BsPatchCheckFill className='experience__details-icon'/>
          <div>
          <h4>HTML</h4>
          <small className='text-light'>Experienced</small>
          </div>
          </article>

          <article className='experience__details'>
          <BsPatchCheckFill className='experience__details-icon'/>
          <div>
          <h4>Javascript</h4>
          <small className='text-light'>Experienced</small>
          </div>
          </article>

          <article className='experience__details'>
          <BsPatchCheckFill className='experience__details-icon'/>
          <div>
          <h4>CSS</h4>
          <small className='text-light'>Experienced</small>
          </div>
          </article>

          <article className='experience__details'>
          <BsPatchCheckFill className='experience__details-icon'/>
          <div>
          <h4>Bootstrap</h4>
          <small className='text-light'>Experienced</small>
          </div>
          </article>

          <article className='experience__details'>
          <BsPatchCheckFill className='experience__details-icon'/>
          <div>
          <h4>React</h4>
          <small className='text-light'>Intermediate</small>
          </div>
          </article>

          <article className='experience__details'>
          <BsPatchCheckFill className='experience__details-icon'/>
          <div>
          <h4>Gatsby</h4>
          <small className='text-light'>Beginner</small>
          </div>
          </article>


        </div>
      </div>
      <div className="experience__cms">
        <h3>CMS</h3>
        <div className="experience__content">
          <article className='experience__details'>
          <BsPatchCheckFill className='experience__details-icon'/>
          <div>
          <h4>Shopify</h4>
          <small className='text-light'>Experienced</small>
          </div>
          </article>

          <article className='experience__details'>
          <BsPatchCheckFill className='experience__details-icon'/>
          <div>
          <h4>Wordpress</h4>
          <small className='text-light'>Experienced</small>
          </div>
          </article>

          <article className='experience__details'>
          <BsPatchCheckFill className='experience__details-icon' />
          <div>
          <h4>Wix</h4>
          <small className='text-light'>Experienced</small>
          </div>
          </article>
        </div>
      </div>
    </div>

    </section>
  )
}

export default Experience