import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'

function HeaderSocials() {
  return (
    <div className="header__socials">
        <a href="https://www.linkedin.com/in/abhishekkaj/" target="_blank"><BsLinkedin /></a>
        <a href="https://github.com/abhishekkaj" target="_blank"><FaGithub /></a>
        {/* <a href="" target="_blank"></a> */}
    </div>
  )
}

export default HeaderSocials