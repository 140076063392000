import React from 'react'
import './about.css'
import ME from '../../assets/AJ-img.png'
import {FaAward} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'
const About = () => {
  return (
    <section id='about'>
    <h5>Get To Know</h5>
    <h2>About Me</h2>

    <div className="container about__container">
      <div className="about__me">
<div className="about__me-img">
  <img src={ME} alt="" />
</div>
      </div>

      <div className="about__content">
      <div className="about__cards">
        <article className='about__card'>
        <FaAward className='about__icon'/>
          <h5>Experience</h5>
          <small>1+ Years</small>
        </article>

        <article className='about__card'>
        <VscFolderLibrary className='about__icon'/>
          <h5>Projects</h5>
          <small>5+ </small>
        </article>
      </div>

      

<p>
Adaptable Computer Science major, with years of work experience in Frontend Development. Aiming to leverage a proven knowledge of
advanced technology, Web Design, and Programming skills to successfully fill as Front-end Developer at your company. Frequently praised as
results-oriented by my peers, I can be relied upon to help your company achieve its goals. 
</p>

<a href="#contact" className='btn btn-primary'>Let's Talk</a>
      </div>
    </div>
    </section>
  )
}

export default About