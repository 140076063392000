import React from 'react'
import './portfolio.css'
import IMG from '../../assets/IMG.png'


const data = [
  {
    id: 1,
    image:IMG,
    title: 'VPN',
    github: 'https://github.com/abhishekkaj/owl-vpn',
    demo: 'https://owl-vpn.netlify.app/'
  },
  {
    id: 2,
    image:IMG,
    title: 'Quiz App',
    github: 'https://quiz-7zn4g8.stackblitz.io/',
    demo: 'https://quiz-7zn4g8.stackblitz.io/'
  },
  {
    id: 3,
    image:IMG,
    title: 'ToDo App',
    github: 'https://github.com/abhishekkaj/ToDo',
    demo: 'https://react-qgcnwj.stackblitz.io'
  },
  {
    id: 4,
    image:IMG,
    title: 'Amazon Clone',
    github: 'https://github.com/abhishekkaj/Amazon-Clone',
    demo: '#'
  },
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
    <h5>My Recent Projects</h5>
    <h2>Portfolio</h2>

    <div className="container portfolio__container">
    
      {
        data.map(({id, image, title, github, demo})=>{
          return(
            <article key={id} className="portfolio__item">
        <div className="portfolio__item-image">
        <img src={image} alt={title} />
        </div>
        <h3>{title}</h3>
        <div className="portfolio__item-cta">
        <a href={github} className='btn'>Github</a>
        <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
        </div>
      </article>    
          )
        })
      }     
    </div>
    </section>
  )
}

export default Portfolio